import { Component, OnInit, ViewChild } from '@angular/core';
import { MappingService } from '../../shared/services/mapping.service';
import * as _ from 'lodash';

import { HeatmapLayer } from '@ngui/map';


@Component({
  selector: 'empire-heatmaps',
  templateUrl: './heatmaps.component.html',
  styleUrls: ['./heatmaps.component.scss'],
  providers: [ MappingService ]
})
export class HeatmapsComponent implements OnInit {

  @ViewChild( HeatmapLayer ) heatmapLayer: HeatmapLayer;
  heatmap: google.maps.visualization.HeatmapLayer;
  map: google.maps.Map;
  public points = [];

  public schools;
  public stateArr;
  public states;
  public StateList;
  public groups;
  public GroupedSchools;
  public filteredSchools;
  public selectedSchool;

  public selectedState;
  public stateSelected;
  public schooleSelected;

  // Heat map data
  public lat;
  public lon;

  public heatdata;
  public publicHeatmap;
  public pointsArr;

  public kml;
  public kmlurl;

  public updatedSchool;

  constructor( public mapping: MappingService ) {
    this.selectedState  = null;
    this.selectedSchool = null;
  }

  async ngOnInit() {
    // Default states for items in html
    this.stateSelected    = false;
    this.schooleSelected  = false;

    // Getting data for schools and states
    this.schools = await this.mapping.getSchools();
    const schoolArr = this.schools.schools;
    this.stateArr = [];
    for (let i = 0, max = schoolArr.length; i < max; i += 1) {
      const st = schoolArr[i].state;
      this.stateArr.push(st);
    }
    this.states = [].concat.apply([], this.stateArr );
    const dedupe = [];
    this.states.forEach(function(item) {
      if (dedupe.indexOf(item) < 0) {
        dedupe.push(item);
      }
    });
    this.StateList = dedupe;
    this.groups = [];
    for ( let i = 0, max = schoolArr.length; i < 1; i += 1) {
      const states = _.groupBy(schoolArr, 'state');
      this.groups.push(states);
    }
    const groupdbyArr = [].concat.apply(this.groups);
    this.GroupedSchools = groupdbyArr[0];

    // Default data to home school (pottsville)

    this.lat = '40.705833';
    this.lon = '-76.186944';

    this.heatdata = await this.mapping.getHeatmap( 2403 );
    this.publicHeatmap = this.heatdata.coords;

    this.heatmapLayer['initialized$'].subscribe( heatmap => {
      this.pointsArr = [];
      for ( let i = 0, max = this.publicHeatmap.length; i < max; i += 1 ) {
        this.pointsArr.push( new google.maps.LatLng(this.publicHeatmap[i].lat, this.publicHeatmap[i].lon) );
      }

      this.points = [].concat.apply(this.pointsArr);

      this.heatmap = heatmap;
      this.map = this.heatmap.getMap();
    });
  }

  async onState(value) {
    this.selectedState = value;
    console.log( 'Selected state', this.selectedState );
    console.log('Schools for that state', this.GroupedSchools[value] );

    this.filteredSchools = this.GroupedSchools[value];
    this.selectedSchool = null;
    this.stateSelected = true;
  }

  async onSchool(value) {
    this.updatedSchool = value;
    this.schooleSelected = true;
    this.heatdata = await this.mapping.getHeatmap( value );
    this.publicHeatmap = this.heatdata.coords;
    this.pointsArr = [];

    for ( let i = 0, max = this.publicHeatmap.length; i < max; i += 1 ) {
      this.pointsArr.push( new google.maps.LatLng(this.publicHeatmap[i].lat, this.publicHeatmap[i].lon) );
    }

    this.points = [].concat.apply(this.pointsArr);

    this.kml = await this.mapping.getHeapmapKML(value);
    this.kmlurl = this.kml.url;
  }

  async onDrop(value) {
    const status = value;

    if ( status === '0' ) {
      this.heatdata = await this.mapping.getHeatmap( this.updatedSchool);
      this.publicHeatmap = this.heatdata.coords;
      this.pointsArr = [];

      for ( let i = 0, max = this.publicHeatmap.length; i < max; i += 1 ) {
        this.pointsArr.push( new google.maps.LatLng(this.publicHeatmap[i].lat, this.publicHeatmap[i].lon) );
      }

      this.points = [].concat.apply(this.pointsArr);

      this.kml = await this.mapping.getHeapmapKML(this.updatedSchool);
      this.kmlurl = this.kml.url;

    }

    if ( status === '1' ) {
      // starts
      this.heatdata = await this.mapping.getHeatmap( this.updatedSchool );
      this.publicHeatmap = this.heatdata.coords;
      this.pointsArr = [];

      for ( let i = 0, max = this.publicHeatmap.length; i < max; i += 1 ) {
        this.pointsArr.push( new google.maps.LatLng(this.publicHeatmap[i].lat, this.publicHeatmap[i].lon) );
      }

      this.points = [].concat.apply(this.pointsArr);

      this.kml = await this.mapping.startKML(this.updatedSchool);
      this.kmlurl = this.kml.url;
    }

    if (status === '2') {
      // drops
      this.heatdata = await this.mapping.getHeatmap( this.updatedSchool );
      this.publicHeatmap = this.heatdata.coords;
      this.pointsArr = [];

      for ( let i = 0, max = this.publicHeatmap.length; i < max; i += 1 ) {
        this.pointsArr.push( new google.maps.LatLng(this.publicHeatmap[i].lat, this.publicHeatmap[i].lon) );
      }

      this.points = [].concat.apply(this.pointsArr);

      this.kml = await this.mapping.dropKML(this.updatedSchool);
      this.kmlurl = this.kml.url;
    }

  }

}
