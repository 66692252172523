import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogsService } from '../../shared/services/catalogs.service';

import * as _ from 'lodash';

@Component({
  selector: 'empie-updater',
  templateUrl: './updater.component.html',
  styleUrls: ['./updater.component.scss'],
  providers: [ CatalogsService ]
})
export class UpdaterComponent implements OnInit {

  public schools;
  public stateArr;
  public states;
  public StateList;
  public groups;
  public GroupedSchools;
  public filteredSchools;

  public selectedState;
  public selectedSchool;
  public stateSelected;
  public schoolSelected;

  public schoolCatalog;
  public selectedCatalog;
  public catalogLoaded;

  public editingCatalog;

  // Form items
  public uploadedPDF;
  public formCatalog;

  public pdfUploaded;

  constructor( public catalog: CatalogsService ) {
    this.selectedState  = null;
    this.selectedSchool = null;
  }

  async ngOnInit() {
    this.stateSelected    = false;
    this.schoolSelected   = false;
    this.catalogLoaded    = false;
    this.editingCatalog   = false;
    this.pdfUploaded      = false;

    // Getting data for schools and states
    this.schools = await this.catalog.getSchools();
    const schoolArr = this.schools.schools;
    this.stateArr = [];
    for (let i = 0, max = schoolArr.length; i < max; i += 1) {
      const st = schoolArr[i].state;
      this.stateArr.push(st);
    }
    this.states = [].concat.apply([], this.stateArr );
    const dedupe = [];
    this.states.forEach(function(item) {
      if (dedupe.indexOf(item) < 0) {
        dedupe.push(item);
      }
    });
    this.StateList = dedupe;
    this.groups = [];
    for ( let i = 0, max = schoolArr.length; i < 1; i += 1) {
      const states = _.groupBy(schoolArr, 'state');
      this.groups.push(states);
    }
    const groupdbyArr = [].concat.apply(this.groups);
    this.GroupedSchools = groupdbyArr[0];
  }

  async onState(value) {
    this.selectedState = value;
    console.log( 'Selected state', this.selectedState );
    console.log('Schools for that state', this.GroupedSchools[value] );

    this.filteredSchools = this.GroupedSchools[value];
    this.selectedSchool = null;
    this.stateSelected = true;
  }

  async onSchool(value) {
    console.log('Getting catalog for campus: ', value);
    const locID = value;
    await this.catalog.getCatalog( locID )
      .then(
        res => {
          this.schoolCatalog = res[0];
          this.selectedCatalog =  this.schoolCatalog;
          console.log('You have selected this catalog information: ', this.selectedCatalog);

          if (res !== undefined ) {
            this.catalogLoaded = true;
          }
        }
      )
      .catch( err => {
        return err;
      });

    console.log(this.schoolCatalog);
  }

  async editCatalog() {
    this.editingCatalog = true;
  }

  async exitEdit() {
    this.editingCatalog = !this.editingCatalog;
  }

  async uploadPDF( event: any ) {
    this.pdfUploaded       = false;
    if ( event.target.files.length ) {
      this.uploadedPDF = event.target.files[0];
    }

    if ( this.uploadedPDF.type !== 'application/pdf' ) {
      this.uploadedPDF = undefined;
      console.log('Wrong file!');
    } else {
      console.log('Correct file!');
      this.formCatalog = this.uploadedPDF;
      this.pdfUploaded = true;
    }

    console.log('Uploaded PDF: ', this.formCatalog);
  }

  /* ===== Form Logic ===== */
  submitCatalogForm( formValue: any ) {
    const fields = this.getSubmitCatalogObj( formValue );
    this.catalog.updatingCatalogURL( fields );

    const submitted = this.catalogAppToFormData( formValue );
    this.catalog.uploadPDF( submitted );
  }

  getSubmitCatalogObj( formValue: any ) : any {
    let catalogApp = {
      CatalogURL: formValue.catalogUpload.catalogName,
      SchoolInfoKey: this.selectedCatalog.SchoolInfoKey,
      LocID: this.selectedCatalog.LocID
    };
    return catalogApp;
  }

  catalogAppToFormData(catalogApp: any) : FormData {
    let formData:FormData = new FormData();
    formData.append('Form', JSON.stringify(catalogApp));
    formData.append('Catalog', this.formCatalog, this.formCatalog.name);
    return formData;
  }

}
