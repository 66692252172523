import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  constructor(private http: HttpClient ) {}

  public getSchools() {
    const url = `https://leads.empire.edu/regiskml.ashx?op=list`;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public getCatalogs() {
    const url = `https://tools.empire-dev.com/pdf/all`;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public getCatalog ( value ) {
    const url = `https://tools.empire-dev.com/pdf/single/` + value;
    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public updatingCatalogURL( catalogForm: FormData ) {
    const url = `https://tools.empire-dev.com/pdf/single/update`;
    return this.http.post(url, catalogForm).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public uploadPDF( catalogForm: FormData ){
    const url = `https://tools.empire-dev.com/pdf/single/upload`;
    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.post(url, catalogForm ).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  // public submitform( catalogForm: FormData ){
  //   const url = `http://localhost:8080`;
  //   const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  //   return this.http.post(url, catalogForm, {headers: headers}).toPromise().then(
  //     res => res
  //   ).catch( (err) => {
  //     return err;
  //   });
  // }

}
