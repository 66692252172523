// core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule }         from '@angular/common/http';  // replaces previous Http service
import { HttpClientJsonpModule  }   from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// addons
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MappingComponent } from './mapping/mapping.component';
import { ProjectUniteComponent } from './mapping/project-unite/project-unite.component';
import { HeatmapsComponent } from './mapping/heatmaps/heatmaps.component';
import { SortPipePipe } from './shared/pipes/sort-pipe.pipe';
import { NguiMapModule} from '@ngui/map';
import { UpdaterComponent } from './catalogs/updater/updater.component';
import { LeadsComponent } from './leads/leads.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    MappingComponent,
    ProjectUniteComponent,
    HeatmapsComponent,
    SortPipePipe,
    UpdaterComponent,
    LeadsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    FormsModule,
    NguiMapModule['forRoot']({ apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDiO2QDXRaR7ccEkDWPygxwHvhFZIx5yRA&libraries=visualization'})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
