import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor(private http: HttpClient ) {}

  public getSchools() {
    const url = `https://leads.empire.edu/regiskml.ashx?op=list`;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public getKML(locid) {
    const stamp = moment().unix();
    const url = `https://leads.empire.edu/regiskml.ashx?op=kml&locid=` + locid + `&dummy=` + stamp;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public getTotals( locid ) {
    const url = `https://leads.empire.edu/regiskml.ashx?op=totalopenings&locid=` + locid;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public getHeapmapKML(locid) {
    const stamp = moment().unix();
    const url = `https://leads.empire.edu/regiskml.ashx?op=leaddensitykml&locid=` + locid + `&dummy=` + stamp;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public getHeatmap( locid ) {
    const stamp = moment().unix();
    const url = `https://leads.empire.edu/regiskml.ashx?op=heatmap&locid=` + locid + `&dummy=` + stamp;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public Starts( locid ) {
    const stamp = moment().unix();
    const url = `https://leads.empire.edu/regiskml.ashx?op=heatmapstarts&locid=` + locid + `&dummy=` + stamp;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get(url, {headers: headers}).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public Drops( locid ) {
    const stamp = moment().unix();
    const url = `https://leads.empire.edu/regiskml.ashx?op=heatmapdrops&locid=` + locid + `&dummy=` + stamp;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get( url, { headers: headers } ).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public startKML( locid ) {
    const stamp = moment().unix();
    const url = `https://leads.empire.edu/regiskml.ashx?op=startdensitykml&locid=` + locid + `&dummy=` + stamp;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get( url, { headers: headers } ).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

  public dropKML( locid ) {
    const stamp = moment().unix();
    const url = `https://leads.empire.edu/regiskml.ashx?op=dropdensitykml&locid=` + locid + `&dummy=` + stamp;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    return this.http.get( url, { headers: headers } ).toPromise().then(
      res => res
    ).catch( (err) => {
      return err;
    });
  }

}
