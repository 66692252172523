import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component';

import { MappingComponent } from './mapping/mapping.component';
import { HeatmapsComponent } from './mapping/heatmaps/heatmaps.component';
import { ProjectUniteComponent } from './mapping/project-unite/project-unite.component';
import { UpdaterComponent } from './catalogs/updater/updater.component';

import { LeadsComponent } from './leads/leads.component';

const routes: Routes = [
  { path: '',         component: HomepageComponent },
  { path: 'mapping',  component: MappingComponent,
    children: [
      { path: '' , redirectTo: 'mapping', pathMatch: 'full' },
    ]
  },
  { path: 'heatmaps',           component: HeatmapsComponent },
  { path: 'project-unite',      component: ProjectUniteComponent },
  { path: 'catalog-updater',    component: UpdaterComponent  },
  { path: 'external-marketing', component: LeadsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
