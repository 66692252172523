import { Component, OnInit } from '@angular/core';
import { MappingService } from '../../shared/services/mapping.service';

import * as _ from 'lodash';

@Component({
  selector: 'empire-project-unite',
  templateUrl: './project-unite.component.html',
  styleUrls: ['./project-unite.component.scss'],
  providers: [ MappingService ]
})
export class ProjectUniteComponent implements OnInit {

  public schools;
  public stateArr;
  public states;
  public StateList;
  public groups;
  public GroupedSchools;
  public filteredSchools;
  public selectedSchool;

  public selectedState;
  public stateSelected;
  public schooleSelected;

  public kmlurl;
  public locationNumbers;

  constructor( public mapping: MappingService ) {
    this.selectedState  = null;
    this.selectedSchool = null;
  }

  async ngOnInit() {
    // Default states for items in html
    this.stateSelected    = false;
    this.schooleSelected  = false;

    // Getting data for schools and states
    this.schools = await this.mapping.getSchools();
    const schoolArr = this.schools.schools;
    this.stateArr = [];
    for (let i = 0, max = schoolArr.length; i < max; i += 1) {
      const st = schoolArr[i].state;
      this.stateArr.push(st);
    }
    this.states = [].concat.apply([], this.stateArr );
    const dedupe = [];
    this.states.forEach(function(item) {
      if (dedupe.indexOf(item) < 0) {
        dedupe.push(item);
      }
    });
    this.StateList = dedupe;
    this.groups = [];
    for ( let i = 0, max = schoolArr.length; i < 1; i += 1) {
      const states = _.groupBy(schoolArr, 'state');
      this.groups.push(states);
    }
    const groupdbyArr = [].concat.apply(this.groups);
    this.GroupedSchools = groupdbyArr[0];

  }

  async onState(value) {
    this.selectedState = value;
    console.log( 'Selected state', this.selectedState );
    console.log('Schools for that state', this.GroupedSchools[value] );

    this.filteredSchools = this.GroupedSchools[value];
    this.selectedSchool = null;
    this.stateSelected = true;
  }

  async onSchool(value) {
    const kml = await this.mapping.getKML(value);
    this.kmlurl = kml.url;
    console.log('KML URL : ', this.kmlurl);

    const numbers = await this.mapping.getTotals( value );
    this.locationNumbers = numbers;
    console.log('Salon numbers: ', this.locationNumbers );

    this.schooleSelected = true;
  }

}
