import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'empie-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss']
})
export class MappingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
