import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'empire-root',
  template: `
    <empire-header></empire-header>
    <router-outlet></router-outlet>
    <!--<empire-footer></empire-footer>-->
  `
})

export class AppComponent implements OnInit {

  constructor(public router: Router) {}

  ngOnInit() {
    // Takes navigated page to top of page
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
